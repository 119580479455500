import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { prism } from "@tsg/1st-grpc-web";

import {
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

export const competitorColumn = (
  handleActionClick,
  t
): ExtendedGridColDef[] => {
  return [
    {
      field: "shortName",
      headerName: t("competition_short_name"),
      width: 50,
      valueGetter: (params: GridValueGetterParams) => params.row.shortName
    },
    {
      field: "displayName",
      headerName: t("competition_display_name"),
      width: 50,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.displayName.value
    },
    {
      field: "displayColor",
      headerName: "Silks Color",
      width: 50,
      valueGetter: (params: GridValueGetterParams) => params.row.displayColor
    },
    {
      field: "racingCompetitor",
      headerName: "Betting Interest",
      width: 10,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.racingCompetitor.bettingInterest
    },
    {
      field: "racingCompetitorHorse",
      headerName: "Horse Name",
      width: 50,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.racingCompetitor.horseName
    },
    {
      field: "state",
      headerName: t("competition_state"),
      width: 50,
      editable: false,
      hide: false,
      valueGetter: (params: GridValueGetterParams) =>
        t(
          `competitor_state_enum.${
            prism.v1.data.CompetitorEnums.State[params.row.state]
          }`
        )
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      editable: false,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleActionClick("edit", params)}
          showInMenu
        />
      ]
    }
  ];
};
export default competitorColumn;
